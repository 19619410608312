<template>
  <v-container :key="reloadKey">
    <v-row class="mt-4">
      <h3>Import Files</h3>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-select
          v-model="current"
          :items="pipelines"
          item-text="description"
          item-value="pipeline"
          label="Type of import"
        ></v-select>
      </v-col>
      <v-col cols="12" md="6">
        <v-file-input :disabled="!current"
          v-model="importClearableModel"
          accept=".txt,.csv"
          label="Import (.txt,.csv)"
          show-size
          clearable
          @change="onFileSelected"
        >
        </v-file-input>
      </v-col>
    </v-row>
    <v-row v-if="importing">
      <v-col cols="2">
        <p>{{statusText}}</p>
      </v-col>
      <v-col cols="2">
        <p>{{upsertedCount}} records...</p>
      </v-col>
      <v-col cols="8">
        <v-progress-linear
          color="deep-purple accent-4"
          :value="progress"
          rounded
          height="6"
        ></v-progress-linear>
      </v-col>
    </v-row>
    <v-row v-if="reload">
      <v-col>
        <v-btn rounded @click="reloadMe">
          <v-icon class="mr-2">{{icons.mdiReload}}</v-icon> Load another
        </v-btn>
        <span> Imported in: {{this.timing}}</span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  mdiReload,
} from '@mdi/js';

import {
  mapActions,
  mapMutations,
} from 'vuex';
import LineNavigator from 'line-navigator';
import { clients } from '../../util/clients';
import { dateDiffStr } from '../../util/shared/tmc-global';

const initdata = () => ({
  icons: {
    mdiReload,
  },
  importClearableModel: undefined,
  current: undefined,
  importing: false,
  reload: false,
  reloadKey: 0,
  statusText: '',
  progress: 0,
  upsertedCount: 0,
  timing: undefined,
});

export default {
  name: 'ImportFilesOrig',
  data: () => initdata(),
  computed: {
    pipelines() {
      return this.$store.state.Bulk.pipelines;
    },
  },
  created() {
    this.$store.dispatch('Bulk/loadProfiles', true);
  },
  methods: {
    ...mapActions('jobs', [
      'backup',
    ]),
    ...mapMutations('jobs', [
      'setPrefix',
      'setGroup',
    ]),
    ...mapActions('Bulk', [
      'upsertODTransactions',
      'importBankVoucher',
    ]),
    ...mapActions([
      'flashError',
      'flashSuccess',
    ]),
    // based on https://github.com/anpur/line-navigator
    async onFileSelected(file) {
      if (file) {
        await this.importFile(file);
      }
    },
    async importFile(file) {
      this.timing = new Date();
      const self = this;
      this.importing = true;
      this.statusText = 'Loading file...';
      let successfulBackup = false;
      if (window.confirm('Would you like to take a backup before bulk changes?')) {
        this.setGroup('tmc-local-backend');
        this.setPrefix('importfilesprebak');
        successfulBackup = await this.backup();
      }
      if (!successfulBackup) {
        this.importing = false;
        this.statusText = '';
        this.importClearableModel = null;
        return;
      }

      let transformUrl = '';

      if (self.current.profile === undefined) {
        transformUrl = `/transform?type=${self.current.type}`;
      } else {
        transformUrl = `/transform?type=${self.current.type}&profile=${self.current.profile}`;
      }
      // cleanup temporary collection before importing data
      if (self.current.action === 'importBankVoucher') {
        await this.$store.dispatch('Bulk/cleanupBankVoucher');
      }

      // eslint-disable-next-line no-undef
      const navigator = new LineNavigator(file, {
        encoding: 'utf8',
        chunkSize: 1024 * 4,
      });

      const firstIndex = 0;
      let headerValues = '';
      let chunkId = 0;
      navigator.readSomeLines(0,
        async function linesReadHandler(err, index, lines, isEof, progress) {
          const fileChunk = [...lines];
          if (err) self.flashError(`Chunk Id ${chunkId} => ${err}`);

          self.statusText = `Importing file (${progress}%) ...`;
          chunkId += 1;

          // Todo - currently assuming that csv have header at first row
          const isCsvFile = file && (file.type === 'text/csv' || file.name.match(/\.csv|.CSV$/g));
          if (isCsvFile
            && self.current.type === 'csv-parser-pipeline'
            && !self.current.noheader
          ) {
            if (chunkId === 1) {
              headerValues = fileChunk[firstIndex];
            } else {
              fileChunk.unshift(headerValues);
            }
          }
          let response;
          try {
            response = await clients.direct.backendRest.post(
              transformUrl, fileChunk.join('\r\n'),
            );
          } catch (error) {
            return clients.handleAxiosErrors(error);
          }

          let responseData = response.data;
          if (typeof responseData !== 'string') {
            // when only one line is imported, it comes back as an obj
            responseData = JSON.stringify(responseData);
          }
          // skip empty objects
          const upserts = JSON.parse(`[${responseData}]`).filter((upsert) => Object.keys(upsert).length > 0);
          const upsertResponse = await self[self.current.action](upserts, chunkId);
          const { ok } = upsertResponse.data[self.current.action].result;
          const { upsertedCount } = upsertResponse.data[self.current.action];
          self.upsertedCount += upsertedCount;

          if (ok) {
            if (!isEof) {
              navigator.readSomeLines(index + lines.length, linesReadHandler);
            } else {
              const startDt = self.timing;
              const endDt = new Date();
              self.timing = dateDiffStr(startDt, endDt);
              self.flashSuccess('Imported!');
              self.reload = true;
            }
          } else {
            // error occured - todo better troubleshooting
          }
          if (progress === 100 && self.current.profile !== undefined) {
            self.$router.push({
              name: 'File Processing Details',
              params: { type: self.current.action },
            });
          }
          return chunkId;
        });
    },
    reloadMe() {
      Object.assign(this.$data, initdata());
      this.reloadKey += 1;
    },
  },
};
</script>
